<template>
</template>

<script>
const CryptoJS = require("crypto-js");
export default {
  name: "signin",
  data() {
    return {}
  },
  methods: {
    Certification() {
      const code = this.$route.query.code;
      const sec_no = this.$route.query.sec_no;
      const body = {code, sec_no};

      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();

      this.$axios.post('/member/email/certification', {req}).then(
          res => {
            if (res.status == 200) {
              if (res.data.code == '200') {
                this.$alert(`${this.$i18n.t('certification.complete')}`).then(
                    () => {
                      this.$router.push({path: "/sign/login"});
                    }
                )
              } else if (res.data.code == '100') {
                this.$alert(`${this.$i18n.t('certification.alertLink')}`).then(
                    () => {
                      this.$router.push({path: "/sign/login"});
                    }
                )
              } else if (res.data.code == '300') {
                this.loading = false;
                this.$alert(`${this.$i18n.t('certification.alertAlreadyLink')}`).then(
                    () => {
                      this.$router.push({path: "/sign/login"});
                    }
                )
              }
            } else {
              this.loading = false;
            }
          }
      );
    },
  },
  created() {
    this.Certification()
  },
};
</script>
